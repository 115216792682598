
import Vue from "vue"
import ListParagon, { Filter } from '@/components/ListParagon.vue'
import OrderWorkerModal from '@/components/order/OrderWorkerModal.vue'
import dateFormatMixin from '@/mixins/DateFormatMixin.vue'
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"
import { state } from "@/plugins/state"

interface APIWorker {
  id: number,
  sex: number,
  profile_picture: string | null,
  first_name: string,
  last_name: string
}
interface APIPeriod {
  id:number,
  started_at: string,
  finished_at: string
}

interface OrderTemplateStatus {
  id: number,
  step: number,
  title: string,
  weight: number|null,
}

  export default Vue.extend({
    name: "OrderList",
    components: {
      ListParagon,
      OrderWorkerModal
    },
    mixins: [
      dateFormatMixin
    ],
    data () {
      return {
        headers: [
          {
            text: this.$vuetify.lang.t("$vuetify.quantity"),
            value: 'quantity',
            sortable: true
          },
          {
            text: this.$vuetify.lang.t("$vuetify.user_name"),
            value: 'user_names',
            sortable: true,
            sortText: this.$vuetify.lang.t("$vuetify.user_name")
          },
          {
            text: this.$vuetify.lang.t("$vuetify.start"),
            value: 'started_at',
            sortable: true,
            sortText: this.$vuetify.lang.t("$vuetify.start")
          },
          {
            text: this.$vuetify.lang.t("$vuetify.position"),
            value: 'position_name',
            sortable: true,
            sortText: this.$vuetify.lang.t("$vuetify.position")
          },
          {
            text: this.$vuetify.lang.t("$vuetify.order_list.follow_up"),
            value: 'follow_up',
            sortable: false
          },
          {
            text: "",
            value: 'details',
            sortable: false
          },
        ],
        workerListHeaders:[
          {
            text: this.$vuetify.lang.t("$vuetify.card"),
            value: 'card',
          }
        ],
        displayWorkersModal: {} as {[key: string]: boolean} ,
        filters: [
          { label: "worker_names", type: "text-field" },
          { label: "user_names", type: "text-field" },
          { label: "position_id", type: "autocomplete", url: "/v1/clients/positions", text: "name", value: "position_id", search: "name" },
          { label: "started_at", type: "date", allowsOnlyOneDate: true, text: this.$vuetify.lang.t("$vuetify.period") },
          { label: "contract_type", type:"select", items:[
            {text: this.$vuetify.lang.t("$vuetify.temporary_work"), value: "temporary_work"},
            {text: this.$vuetify.lang.t("$vuetify.permanent"), value: "permanent"},
            {text: this.$vuetify.lang.t("$vuetify.temporary"), value: "temporary"},
            {text: this.$vuetify.lang.t("$vuetify.individual_contractor_aggrements"), value: "individual_contractor_aggrements"},
            {text: this.$vuetify.lang.t("$vuetify.subcontracting"), value: "subcontracting"},
            {text: this.$vuetify.lang.t("$vuetify.training"), value: "training"},
          ], multiple: true},
          { label: "client_id", type: "autocomplete", url: "/v1/clients", text: "name", value: "id", search: "name" }
        ] as Array<Filter>,
        follow_up: "to_process", // current selected tab & follow_up filter value used on url computed
        follow_upValues: [ // available values for follow_up data
          "to_process",
          "start",
          "contract_to_do"
        ],
        workerList: [] as APIWorker[],
        orderWorkersUpdate: {} as {[id: string]: {["status"]: string, ['periods']?: number[]|null}},
        reload: 0,
        orderPeriods: [] as APIPeriod[],
        disableButton: true,
        statusIdListWithWeightNotNull : [] as number[]
      }
    },
    computed: {
      /**
       * Return ListParagon url, based on selected follow_up tab
       *
       * @return computed url
       */
      url() {
        let parameters = []
        let url = "/v1/orders"
        if (state.agency !== null) {
          parameters.push(`spaces=${state.agency}`) 
        }
        if (this.follow_upValues.includes(this.follow_up)) {
          parameters.push(`follow_up=${this.follow_up}`)
        }
        for (let index = 0; index < parameters.length; index++) {
          if (index === 0) {
            url +="?" + parameters[index];
          } else {
            url += "&" + parameters[index];
          }
        }
        return url
      }
    },
    methods: {
      /**
       * method to assign new status to worker
       * @param data built with key worker id and value status id
       */
      assignStatusToWorker(data: {[key:string]: string}){
        let key = Object.keys(data)[0]
        if (this.orderWorkersUpdate[key]) {
          this.orderWorkersUpdate[key].status = data[key]
        } else {
          this.orderWorkersUpdate[key] = {"status": data[key], "periods": null}
        }
        this.isUpdateDisabled()
      },
      /**
       * method to assign new periods to worker
       * @param data built with key worker id and value periods array of period id
       */
      assignPeriodsToWorker(data:  {[key:string]: number[]}) {
        let key = Object.keys(data)[0]
        // this.orderWorkersUpdate[key].periods exist beccause need to change status to show select
        this.orderWorkersUpdate[key].periods = data[key]
        this.isUpdateDisabled()
      },
      /**
       * Sets a color depending of the completion level between a total on the current value
       *
       * @param value The current value
       * @param total The total
       *
       * @return The hexadecimal code for the suitable color
       */
      setColor(value: number, total: number): string
      {
        let color = "#d5e8d4" //green
        if (!value) {
          color = "#f8cecc" //red
        } else if (value && value < total) {
          color = "#ffe6cc" //orange
        }
        return color
      },
      /**
       * method call to check if update button is disabled when period is require
       */
      isUpdateDisabled()
      {
        if (this.orderPeriods.length !== 0) {
          for(const workerId in this.orderWorkersUpdate) {
            if ((!this.orderWorkersUpdate[workerId].periods || this.orderWorkersUpdate[workerId].periods?.length === 0) &&
              this.statusIdListWithWeightNotNull.includes(+this.orderWorkersUpdate[workerId].status)) {
              this.disableButton = true
              break
            } else {
              this.disableButton = false
            }
          }
        } else {
          this.disableButton = false
        }
      },
      getOrderWorkersPerStatus(orderId: number, statusId: number, listStatus: OrderTemplateStatus[]): void
      {
        this.statusIdListWithWeightNotNull = []
        // get list of status id with weight !== null to check disable update
        for (let status of listStatus) {
          if (status.weight !== null) {
            this.statusIdListWithWeightNotNull.push(status.id)
          }
        }
        // Reset the list before each fetch
        this.workerList = []
        // reset the updateStatus selects to prevent unwanted workers to be updated
        this.orderWorkersUpdate = {};
        let recalls: Promise<AxiosResponse>[] = []
        axios.get(`/v1/workers?page=1&order=true:${orderId}:${ statusId}&fields=id,sex,profile_picture,first_name,last_name`)
        .then((response: AxiosResponse) => {
          // store retrieved super_workers
          this.workerList = this.workerList.concat(response.data)
          // prepare recalls if more pages are available
          let match = /page=(\d+)[^>]*>;\s*rel="last"/m.exec(response.headers.link)
          if (match && match[1] && parseInt(match[1]) > 1) {
            for (let i = 2; i <= parseInt(match[1]); i++) {
              recalls.push(
                axios.get(`/v1/workers?page=${i}&order=true:${orderId}:${statusId}&fields=id,sex,profile_picture,first_name,last_name`)
              )
            }
          }
        })
        .then(() => {
          // execute recalls if any
          Promise.all(recalls)
          .then((results) => {
            results.forEach((response: AxiosResponse) => {
              // store retrieved super_workers
              this.workerList = this.workerList.concat(response.data)
            })
          })
        })
        .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error})
        })
        // get order periods
        axios.get(`/v1/orders/${orderId}/periods`)
        .then((response: AxiosResponse) => {
          this.orderPeriods = response.data // select all periods by default
        }).catch((error: AxiosError) => {
          EventBus.$emit('snackbar', { axiosError: error })
        })
      },
      /**
       * Updates a list of order-worker statuses
       *
       * @param orderId The ID of the order
       * @param statusId The initial status of the workers
       */
      updateOrderWorkerStatus(orderId: number, statusId: number): boolean|void
      {
        // If no statuses are to be updated
        if (Object.keys(this.orderWorkersUpdate).length === 0) {
          this.displayWorkersModal[orderId+'-'+statusId] = false
          return false
        }
        for(let workerId in this.orderWorkersUpdate) {
          if (this.orderWorkersUpdate[workerId].periods === null || this.orderWorkersUpdate[workerId].periods?.length === 0) {
            delete this.orderWorkersUpdate[workerId].periods
          }
        }
        axios.put(`/v1/orders/${orderId}/workers`, this.orderWorkersUpdate)
        .then((response: AxiosResponse) => {
          // close the modal
          this.displayWorkersModal[orderId+'-'+statusId] = false
          // reset the updateStatus selects
          this.orderWorkersUpdate = {};
          // refetch the orders data
          (this.$refs.list as InstanceType<typeof ListParagon>)?.getData()
          // refresh the DOM
          this.reload++
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.update_success')})
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
      }
    }
  })
  